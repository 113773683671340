import { Component, OnInit } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { environment } from '../../environments/environment'
import { Platform } from '@angular/cdk/platform'
import { MatDialog } from '@angular/material/dialog'
import { DialogBasicComponent } from '../dialog-basic/dialog-basic.component'

@Component({
    selector: 'app-redirect-appstore',
    templateUrl: './redirect-appstore.component.html',
    styleUrls: ['./redirect-appstore.component.scss'],
})
export class RedirectAppstoreComponent implements OnInit {
    brandCode: string = environment.brandCode
    env: string = environment.env

    constructor(private platform: Platform, private meta: Meta, private matDialog: MatDialog) {
        console.log(`platform.IOS: ${platform.IOS}`)
        console.log(`platform.ANDROID: ${platform.ANDROID}`)
        if (platform.IOS) {
            this.meta.addTag({
                'http-equiv': 'refresh',
                content: `0;url=${environment.storeURL.ios}`,
            })
        } else if (platform.ANDROID) {
            this.meta.addTag({
                'http-equiv': 'refresh',
                content: `0;url=${environment.storeURL.android}`,
            })
        }
    }

    ngOnInit(): void {
        if (this.platform.IOS === false && this.platform.ANDROID === false) {
            this.matDialog.open(DialogBasicComponent, {
                data: {
                    title: '非対応ブラウザです',
                    message: 'iOSもしくAndroidのブラウザをご利用ください',
                },
                height: '300px',
                width: '500px',
                disableClose: false,
            })
        }
    }
}
