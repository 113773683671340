import { NgModule } from '@angular/core'
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireAuthModule } from '@angular/fire/compat/auth'
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { DialogBasicComponent } from './dialog-basic/dialog-basic.component'
import { HomeComponent } from './home/home.component'
import { RedirectAppstoreComponent } from './redirect-appstore/redirect-appstore.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { PlatformModule } from '@angular/cdk/platform'
import { RouterModule } from '@angular/router'

@NgModule({
    declarations: [AppComponent, HomeComponent, RedirectAppstoreComponent, PageNotFoundComponent, DialogBasicComponent],
    entryComponents: [DialogBasicComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        MatDialogModule,
        BrowserAnimationsModule,
        PlatformModule,
        RouterModule,
    ],
    providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }],
    bootstrap: [AppComponent],
})
export class AppModule {}
