import { Component, OnInit } from '@angular/core'
import { environment } from '../../environments/environment'

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    brandCode: string = environment.brandCode
    env: string = environment.env

    constructor() {}

    ngOnInit(): void {
        console.log(`Welcome to ${this.brandCode} ${this.env === 'dev' ? '(dev)' : ''} web application`)
    }
}
