export const environment = {
    production: false,
    brandCode: 'marugen',
    env: 'dev',
    firebase: {
        apiKey: 'AIzaSyARuFzM4_7dwXwRIbhwylrByYAljDc1Jzw',
        authDomain: 'mc-app-marugen-dev.firebaseapp.com',
        projectId: 'mc-app-marugen-dev',
        storageBucket: 'mc-app-marugen-dev.appspot.com',
        messagingSenderId: '68013787131',
        appId: '1:68013787131:web:122e79f275431955dced5e',
        measurementId: 'G-00D177WPZ8',
    },
    hostingBaseURL: 'https://app-dev.syodai-marugen.jp',
    appScheme: 'marugen-monogatari-app-dev',
    storeURL: {
        ios: 'https://apps.apple.com/app/id1499042289',
        android: 'https://play.google.com/store/apps/details?id=jp.syodai_marugen.members',
    },
}
