import { Component, OnInit } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { environment } from '../../environments/environment'
import { Router } from '@angular/router'

@Component({
    selector: 'app-redirect-app',
    templateUrl: './redirect-app.component.html',
    styleUrls: ['./redirect-app.component.scss'],
})
export class RedirectAppComponent implements OnInit {
    brandCode: string = environment.brandCode
    env: string = environment.env

    constructor(private router: Router, private meta: Meta) {
        const currentURL = new URL(`${environment.hostingBaseURL}${this.router.url}`)
        const verifier = currentURL.searchParams.get('verifier')
        const redirectPath = currentURL.searchParams.get('redirect_path')
        const extraData = currentURL.searchParams.get('extra_data')
        let url = `${environment.appScheme}://${redirectPath}?verifier=${verifier}`
        if (extraData) {
            url = `${url}&extra_data=${extraData}`
        }
        this.meta.addTag({
            'http-equiv': 'refresh',
            content: `0;url=${url}`,
        })
    }

    ngOnInit(): void {}
}
