import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'app-dialog-basic',
    templateUrl: './dialog-basic.component.html',
    styleUrls: ['./dialog-basic.component.scss'],
})
export class DialogBasicComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public matDialogRef: MatDialogRef<DialogBasicComponent>) {}

    ngOnInit(): void {}

    onClickOkButton(): void {
        // OKボタンが押されたときは「OK」を呼び出し元に渡す。
        this.matDialogRef.close('OK')
    }
}
