import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute } from '@angular/router'
import { environment } from '../../environments/environment'
import { DialogBasicComponent } from '../dialog-basic/dialog-basic.component'

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
    brandCode: string = environment.brandCode
    mode: string = ''
    actionCode: string = ''
    continueUrl: string | undefined = undefined
    lang: string = ''

    constructor(
        private auth: AngularFireAuth,
        private route: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document,
        private matDialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            console.log(params) // { order: "popular" }

            this.mode = params.mode || ''
            this.actionCode = params.oobCode || ''
            this.continueUrl = params.continueUrl
            this.lang = params.lang || 'ja'
        })
        // this.auth.checkActionCode('test')
        if (this.mode === '') {
            this.matDialog.open(DialogBasicComponent, {
                data: {
                    title: '不正なURLです',
                    message: 'Emailに届いたURLをクリックしてください',
                },
                height: '300px',
                width: '500px',
                disableClose: false,
            })
            return
        }

        switch (this.mode) {
            case 'recoverEmail':
                handleRecoverEmail(this.auth, this.actionCode, this.document, this.matDialog)
                break
            case 'resetPassword':
            case 'verifyEmail':
            default:
                this.document.location.href = `https://${environment.firebase.authDomain}/__/auth/action?mode=${this.mode}&oobCode=${this.actionCode}&apiKey=${environment.firebase.apiKey}&lang=${this.lang}`
        }
    }
}

const handleRecoverEmail = async (auth: AngularFireAuth, actionCode: string, document: Document, matDialog: MatDialog): Promise<void> => {
    // let previousEmail: string | null = null
    // let restoredEmail: string | null = null

    // 変更前のメールアドレスを取得する
    const info = await auth.checkActionCode(actionCode).catch((err) => {
        matDialog.open(DialogBasicComponent, {
            data: {
                title: 'メール変更のリセットに失敗しました',
                message: 'メール変更をリセットする有効期限が切れています。お手数ですがアプリにもどって再度メール変更をしてください',
            },
            height: '300px',
            width: '500px',
            disableClose: false,
        })
        throw err
    })
    console.log(info)
    // if (info.data.email !== undefined) {
    //     restoredEmail = info.data.email
    // }
    // if (restoredEmail === null) {
    //     return Promise.resolve()
    // }
    // if (info.data.previousEmail !== undefined) {
    //     previousEmail = info.data.previousEmail
    // }
    document.location.href = `${environment.appScheme}://app/recover_email?action_code=${actionCode}`
}
