import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './home/home.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { UserManagementComponent } from './user-management/user-management.component'
import { RedirectAppstoreComponent } from './redirect-appstore/redirect-appstore.component'
import { RedirectAppComponent } from './redirect-app/redirect-app.component'
import { RedirectAppLoginComponent } from './redirect-app-login/redirect-app-login.component'

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'user_management', component: UserManagementComponent },
    { path: 'redirect_appstore', component: RedirectAppstoreComponent },
    { path: 'redirect_app', component: RedirectAppComponent },
    { path: 'redirect_app_login', component: RedirectAppLoginComponent },
    { path: '**', component: PageNotFoundComponent },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
