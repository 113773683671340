import { Component, OnInit } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { environment } from '../../environments/environment'
import { Router } from '@angular/router'

@Component({
    selector: 'app-redirect-app-login',
    templateUrl: './redirect-app-login.component.html',
    styleUrls: ['./redirect-app-login.component.scss'],
})
export class RedirectAppLoginComponent implements OnInit {
    brandCode: string = environment.brandCode
    env: string = environment.env

    constructor(private router: Router, private meta: Meta) {
        const currentURL = new URL(`${environment.hostingBaseURL}${this.router.url}`)
        const callback = currentURL.searchParams.get('callback')
        const url = `${environment.appScheme}://app/login?callback=${callback}`
        this.meta.addTag({
            'http-equiv': 'refresh',
            content: `0;url=${url}`,
        })
    }

    ngOnInit(): void {}
}
